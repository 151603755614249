<template>
  <div>
<div class=" text-right">
  <locale-a />
</div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <img src="../assets/images/logo/logo.png" alt="logo" width="75" />
            <img
              src="../assets/images/logo/logop2.png"
              class="mt-2 ml-1"
              alt="logo"
              height="35"
            />
          </b-link>
  
          <b-card-title class="mb-1"
            > {{ $t('Forget Password') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            <!-- {{ $t('Observation')}} -->
          </b-card-text>
  
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label-for="email" :label="$t('Email')">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                    autofocus
                  />
                </validation-provider>
              </b-form-group>
  
              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
                @click="forgetpassword"
              >
                {{ $t('Verify Email') }}
              </b-button>
              <b-button
                variant="outline-primary"
                block
                @click="goBackToLogin"
              >
                {{ $t('To Go Back') }}
              </b-button>
              
            </b-form>
          </validation-observer>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import localeA from "@core/layouts/components/app-navbar/components/LocaleA"



export default {
  components: {
    localeA,
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      change_password: true,
      email: "",
      errors: undefined,
    };
  },
  methods: {
    goBackToLogin() {
        this.$router.push('/login')
    },
    forgetpassword(){
      // 'under construction','in construção'
      this.$message(
              this.$t('Success'),
              this.$t('under construction'),
              "success"
          );
      return this.goBackToLogin()
    }
   
  },
  mounted() {
    
  },
  
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
